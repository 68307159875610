import React from 'react';

import MainHeader from 'vatix-ui/lib/components/MainHeader';

import Record from 'vatix-ui/lib/containers/Record/Record';

import { Button } from 'vatix-ui/lib/components/Button';

import routes from 'core/routes';
import { useNavBarHeight } from 'utils/hooks/navbar';

import AddEntityModal from 'components/Entities/AddEntityModal/AddEntityModal';
import { useStore } from 'utils/hooks/store';

import AssetsTable from './components/AssetsTable';

const Assets: React.FunctionComponent = (): React.ReactElement => {
  const navBarHeight = useNavBarHeight();
  const { assetDetails } = useStore();
  const [openModal, setOpenModal] = React.useState(false);
  const refreshRef = React.useRef<Function | null>(null);

  const onClose = (created: boolean): void => {
    setOpenModal(false);
    if (refreshRef.current && created) {
      refreshRef.current();
    }
  };
  const onRefreshCallback = (callback: Function): void => {
    refreshRef.current = callback;
  };

  const handleNewButtonClick = (): void => {
    setOpenModal(true);
  };

  return (
    <>
      <Record
        Header={
          <MainHeader
            title="Assets"
            breadcrumbs={[
              { label: 'Assets', link: routes.dashboard.assets.toString() },
              { label: 'Overview', link: routes.dashboard.assets.toString() },
            ]}
            action={
              <Button variant="contained" size="large" onClick={handleNewButtonClick}>
                New
              </Button>
            }
          />
        }
        Content={(onError) => <AssetsTable onError={onError} onRefreshCallback={onRefreshCallback} />}
        navBarHeight={navBarHeight}
      />
      <AddEntityModal openModal={openModal} store={assetDetails} onClose={onClose} />
    </>
  );
};

export default Assets;

import React from 'react';

import TabPanel from 'components/CustomTabPanel/CustomTabPanel';

import { StyledTab, StyledTabs, TabsContainer } from './styles';
import EntityDetailsTab from '../EntityDetailsTab';
import EntityRelatedTab from '../EntityRelatedTab';

import { EntityDetailsContentProps } from './types';

const EntityDetailsContent: React.FC<EntityDetailsContentProps> = ({ onError, store }) => {
  const [value, setValue] = React.useState(0);
  const handleChange = (_: React.SyntheticEvent, newValue: number): void => {
    setValue(newValue);
  };

  return (
    <TabsContainer>
      <StyledTabs value={value} onChange={handleChange}>
        <StyledTab label="Details" id="overview-tab" />
        <StyledTab label="Related" id="related-tab" />
      </StyledTabs>
      <TabPanel value={value} index={0}>
        <EntityDetailsTab store={store} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <EntityRelatedTab onError={onError} store={store} />
      </TabPanel>
    </TabsContainer>
  );
};

export default EntityDetailsContent;

import React from 'react';
import { useHistory } from 'react-router-dom';
import { styled } from 'styled-components';

import { reverse } from 'named-urls';

import { LinksType } from 'utils/api/types';

import routes from 'core/routes';

const StyledRedirect = styled.div`
  color: #0772e5;
  font-size: 16px;
  text-decoration-line: underline;
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
`;

const RelatedToIncidentValue = styled.p`
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 0.15px;
  margin: 0;
`;

const SiteDisplayValue: React.FC<{ link: LinksType | undefined }> = ({ link }) => {
  const history = useHistory();

  if (link === undefined) {
    return null;
  }

  const navigateToSite = (event: React.MouseEvent): void => {
    const siteId = link.instance.uuid as string;
    history.push(reverse(routes.dashboard.sites.details, { siteId }));
    event.stopPropagation();
  };

  return (
    <StyledRedirect onClick={navigateToSite} role="link">
      <RelatedToIncidentValue id="linked-site">{link.instance.name}</RelatedToIncidentValue>
    </StyledRedirect>
  );
};

export default SiteDisplayValue;

import { useHistory } from 'react-router-dom';

import React from 'react';
import { reverse } from 'named-urls';
import { GridEventListener, GridRowParams, GridValueGetterParams } from '@mui/x-data-grid';

import { formatDateTime } from 'vatix-ui/lib/utils/formatters/time';

import routes from 'core/routes';

import Grid, { defaultOperators, defaultRenderers } from 'components/Grid';
import { ColumnDefinition } from 'components/Grid/types';

import { TaskStatus } from 'core/constants';

import { listRenderer } from 'components/Grid/renderers/GridListRenderer';
import { TaskStatusOperator } from 'components/Grid/operators/Status/';
import { ListRendererType } from 'components/Grid/renderers/GridListRenderer/types';

import { Task } from 'utils/api/types';

import { ExtendedUserOperators } from 'components/Grid/operators/User/User';

import { useStore } from 'utils/hooks/store';

import SiteGridColumn from 'components/Grid/components/SiteGridColumn';

import SessionUser from 'stores/Session/SessionUser';

import TaskLinkedToCell from '../TaskLinkedToCell';
import { NotLinked } from '../TaskLinkedToCell/styles';
import { TasksTableProps } from './types';
import { StyledTaskChip, StyledTaskDueDate } from './styles';
import ActionCell from '../ActionCell';

export const tasksTableBasicColumns = ([
  {
    field: 'name',
    headerName: 'Task name',
    nativeFilter: true,
  },
  {
    defaultVisibility: false,
    field: 'description',
    headerName: 'Description',
    nativeFilter: true,
  },
  {
    field: 'customIncident',
    headerName: 'Linked to',
    renderCell: listRenderer((TaskLinkedToCell as unknown) as ListRendererType, 1, undefined, {
      empty: <NotLinked>Not Linked</NotLinked>,
    }),
    valueGetter: ({ value, row }: GridValueGetterParams) =>
      value !== null
        ? [
            {
              entity: 'incident',
              instance: {
                uuid: value.uuid,
                name: value.subject,
              },
              extraData: value,
            },
            // @ts-ignore
            ...row.links.filter((link) => link.entity !== 'site'),
          ]
        : // @ts-ignore
          row.links.filter((link) => link.entity !== 'site'),
    nativeFilter: 'linkedTo',
  },
  {
    field: 'assigned',
    headerName: 'Assigned to',
    renderCell: defaultRenderers.user,
    filterOperators: ExtendedUserOperators(),
    valueGetter: ({ value }: GridValueGetterParams) => ({ value }),
    nativeFilter: true,
    // ToDo: implement sorting by user on backend
    sortable: false,
  },
  {
    field: 'status',
    headerName: 'Status',
    renderCell: ({ value }: { value: TaskStatus }) => <StyledTaskChip status={value} />,
    filterOperators: TaskStatusOperator(),
    nativeFilter: true,
  },
  {
    defaultVisibility: false,
    field: 'lastUpdate',
    headerName: 'Last update',
    renderCell: ({ value }: { value: string }) => <p>{formatDateTime(value)}</p>,
    filterOperators: defaultOperators.date_time(),
    nativeFilter: true,
  },
  {
    field: 'dueDate',
    headerName: 'Due date',
    renderCell: ({ value }: { value: string }) => <StyledTaskDueDate date={value} />,
    filterOperators: defaultOperators.date(),
    nativeFilter: true,
  },
  {
    defaultVisibility: false,
    field: 'created',
    headerName: 'Created',
    renderCell: ({ value }: { value: string }) => <p>{formatDateTime(value)}</p>,
    filterOperators: defaultOperators.date_time(),
    nativeFilter: true,
  },
  {
    defaultVisibility: false,
    field: 'creator',
    headerName: 'Created by',
    renderCell: defaultRenderers.user,
    filterOperators: defaultOperators.user(),
    valueGetter: ({ value }: GridValueGetterParams) => ({ value }),
    nativeFilter: true,
  },
  {
    field: 'actions',
    type: 'actions',
    headerName: '',
    maxWidth: 50,
    ariaLabel: 'actions_cell',
    renderCell: ({ row, colDef }: { row: Task; colDef: ColumnDefinition }) => (
      <ActionCell rowData={row} actions={colDef.actions} />
    ),
    filterOperators: null,
    sortable: false,
  },
] as unknown) as ColumnDefinition[];

const TasksTable = ({ onError, onRefreshCallback }: TasksTableProps): React.ReactElement => {
  const history = useHistory();
  const onRowClick: GridEventListener<'rowClick'> = (task: GridRowParams): void => {
    history.push(reverse(routes.dashboard.tasks.details, { taskId: task.row.uuid }));
  };

  const {
    session: { user, hasAccessToSitesModule },
  } = useStore();

  return (
    <Grid
      basicColumns={
        hasAccessToSitesModule(user as SessionUser)
          ? [...tasksTableBasicColumns, SiteGridColumn]
          : tasksTableBasicColumns
      }
      filters={[{ field: 'status', operator: 'is not', value: `-${TaskStatus.Completed}` }]}
      entity="action"
      onError={onError}
      onRefreshCallback={onRefreshCallback}
      onRowClick={onRowClick}
      dataURL="/api/tasks/"
      sortBy={{ field: 'dueDate', sort: 'asc' }}
      customNoRowsText="No tasks have been created yet"
    />
  );
};

export default TasksTable;

import React from 'react';
import { Collapse, Grid } from '@mui/material';

import { ExpandMore, ExpandLess, Edit } from '@mui/icons-material';

import { Button } from 'vatix-ui/lib/components/Button';

import { SectionGrid } from 'components/BackgroundInformation/styles';

import CustomFieldDisplayValue from 'containers/IncidentDetails/components/CustomFieldDisplayValue';

import { ProtectorType } from 'utils/api/types';

import { EntitiesStores } from '../types';

import { SectionContainer, Section, SectionContainerHeader, Title, TitleWrapper } from './styles';
import EntityRowWrapper from './RowWrapper';
import EditEntitySection from '../EditEntitySection';

type EntitySectionProps = {
  title: string;
  fieldOrder: string[];
  store: EntitiesStores;
};

const EntitySection: React.FunctionComponent<EntitySectionProps> = ({
  title,
  fieldOrder,
  store,
}): React.ReactElement => {
  const entity = store.details?.entity || {};

  const [keyFieldsExpanded, setKeyFieldsExpanded] = React.useState(true);

  const handleExpandClick = (): void => {
    setKeyFieldsExpanded((prevState) => !prevState);
  };

  const [editing, setEditing] = React.useState(false);

  const onEdit = (): void => {
    setEditing(true);
  };

  return (
    <Section>
      <SectionContainerHeader $expanded={keyFieldsExpanded}>
        <TitleWrapper onClick={handleExpandClick}>
          {keyFieldsExpanded ? (
            <ExpandLess aria-labelledby="show less" fillOpacity="0.54" />
          ) : (
            <ExpandMore aria-labelledby="show more" fillOpacity="0.54" />
          )}
          <Title id="section-title">{title}</Title>
        </TitleWrapper>
        {!editing && (
          <SectionGrid item container xs={2} justifyContent="flex-end">
            <Button onClick={onEdit} id="edit-entity-button">
              <Edit style={{ width: 20, height: 20, marginRight: '8px' }} />
              Edit
            </Button>
          </SectionGrid>
        )}
      </SectionContainerHeader>
      <Collapse in={keyFieldsExpanded} style={{ padding: '0 16px' }}>
        {editing ? (
          <EditEntitySection setEditing={setEditing} fieldOrder={fieldOrder} entity={entity} store={store} />
        ) : (
          <SectionContainer>
            <Grid container spacing={0}>
              {fieldOrder.map((i, index) => {
                const item = entity[i];
                if (item === undefined) {
                  return null;
                }
                return (
                  <EntityRowWrapper rowName={item.schema.name || 'N/A'} withDivider={index !== fieldOrder.length - 1}>
                    <CustomFieldDisplayValue
                      value={item.value}
                      type={item.schema.protectorType as ProtectorType}
                      schema={item.schema}
                    />
                  </EntityRowWrapper>
                );
              })}
            </Grid>
          </SectionContainer>
        )}
      </Collapse>
    </Section>
  );
};

export default EntitySection;

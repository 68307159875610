import React from 'react';

import { MainHeader } from 'vatix-ui/lib/components/MainHeader/MainHeader';

import { Button } from 'vatix-ui/lib/components/Button';

import { useSearch } from 'vatix-ui/lib/utils/hooks/search';

import { UuidableName } from 'vatix-ui/lib/utils/api/types';

import { reverse } from 'named-urls';

import routes from 'core/routes';

import CustomModal from 'components/CustomModal';

import { StyledAutocomplete, StyledTextField } from 'components/Input/styles';
import API from 'utils/api';

import { useStore } from 'utils/hooks/store';

import { TemplateSearchResponse } from 'utils/api/types';

import { InputHeader, StyledOption } from './styles';

const Forms = (): React.ReactElement => {
  const { formDetails, notification, routing } = useStore();

  const [open, setOpen] = React.useState(false);
  const [template, setTemplate] = React.useState<TemplateSearchResponse | null>(null);

  const openModal = (): void => {
    setOpen(true);
  };

  const closeModal = (): void => {
    setOpen(false);
  };

  const createNewForm = async (): Promise<void> => {
    if (!template) {
      notification.enqueueErrorSnackbar('Please select a template');
      return;
    }
    const created = await formDetails.createInspection(template.lastTemplateVersion, template.name);
    if (created) {
      routing.push(reverse(routes.dashboard.audits.forms.details, { formId: created.uuid }));
      closeModal();
    }
  };

  const { results, loading, searchQuery } = useSearch(API.searchTemplates);

  return (
    <>
      <MainHeader
        title="Forms"
        breadcrumbs={[
          { label: 'Audits', link: routes.dashboard.audits.forms.toString() },
          { label: 'Forms', link: routes.dashboard.audits.forms.toString() },
        ]}
        action={
          <Button data-testid="new-form-btn" variant="contained" onClick={openModal}>
            New
          </Button>
        }
      />

      <CustomModal
        openModal={open}
        onClose={closeModal}
        modalTitle="New Form"
        cancelButton={
          <Button id="cancel-action-button" onClick={closeModal} variant="outlined" size="large">
            Cancel
          </Button>
        }
        submitButton={
          <Button
            id="add-action-button"
            onClick={createNewForm}
            disabled={template === null}
            variant="contained"
            size="large"
          >
            Continue
          </Button>
        }
      >
        <InputHeader>Form Template</InputHeader>
        <StyledAutocomplete
          id="form-template-dropdown-field"
          data-testid="form-template-dropdown-field"
          options={results}
          loading={loading}
          getOptionLabel={(option) => option.name}
          renderInput={(params) => (
            <StyledTextField
              {...params}
              placeholder="Select Template"
              onChange={(e) => {
                searchQuery(e.target.value);
              }}
            />
          )}
          onChange={(_, value) => {
            setTemplate(value);
          }}
          renderOption={(props, option: UuidableName) => <StyledOption {...props}>{option.name}</StyledOption>}
          style={{
            height: 'fit-content',
            width: '100%',
          }}
          size="medium"
          sx={{
            '& + .MuiAutocomplete-popper .MuiAutocomplete-option': {
              fontSize: '16px',
            },
            '& + .MuiChip-root': {
              fontSize: '16px',
            },
          }}
        />
      </CustomModal>
    </>
  );
};

export default Forms;
